var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c(
        "section",
        [
          _c(
            "div",
            { staticClass: "lookalike-segment__container" },
            [
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass:
                      "container__wrapper container__wrapper--height",
                  },
                  [
                    _c("div", { staticClass: "container__header" }, [
                      _c("h5", { staticClass: "container__header-title" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("lookalike.segmentProperties")) +
                            " "
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "container__content-lookalike" },
                      [
                        _c(
                          "b-col",
                          {
                            staticClass: "input__wrapper pl-0",
                            attrs: { lg: "6" },
                          },
                          [
                            _c("label", { staticClass: "mr-1" }, [
                              _vm._v(
                                _vm._s(_vm.$t("lookalike.segmentName")) + " *"
                              ),
                            ]),
                            _c("b-form-input", {
                              staticClass: "form__input",
                              class: {
                                "form__input--invalid":
                                  _vm.lookalikeSegment.lookalikeSegmentName
                                    .errors.length > 0,
                              },
                              attrs: {
                                id: "lookalike__segment-name",
                                placeholder: _vm.$t(
                                  "lookalike.enterLookalikeSegmentName"
                                ),
                                type: "text",
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.handleFormInput(_vm.formState, [
                                    _vm.lookalikeSegment.lookalikeSegmentName,
                                  ])
                                },
                              },
                              model: {
                                value:
                                  _vm.lookalikeSegment.lookalikeSegmentName
                                    .value,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.lookalikeSegment.lookalikeSegmentName,
                                    "value",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression:
                                  "lookalikeSegment.lookalikeSegmentName.value",
                              },
                            }),
                            _vm._l(
                              _vm.lookalikeSegment.lookalikeSegmentName.errors,
                              function (error, errorIndex) {
                                return _c(
                                  "div",
                                  {
                                    key: errorIndex,
                                    staticClass: "error__info-message",
                                  },
                                  [_c("span", [_vm._v(_vm._s(error))])]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                        _c(
                          "b-col",
                          {
                            staticClass: "input__wrapper pl-0",
                            attrs: { lg: "6" },
                          },
                          [
                            _c("label", { staticClass: "mr-1" }, [
                              _vm._v(_vm._s(_vm.$t("audience.code"))),
                            ]),
                            _c("b-form-input", {
                              staticClass: "form__input",
                              attrs: { disabled: "", type: "text" },
                              model: {
                                value: _vm.lookalikeSegment.code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.lookalikeSegment, "code", $$v)
                                },
                                expression: "lookalikeSegment.code",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          {
                            staticClass: "input__wrapper pl-0",
                            attrs: { lg: "6" },
                          },
                          [
                            _c("label", { staticClass: "mr-1" }, [
                              _vm._v(
                                _vm._s(_vm.$t("lookalike.predictedUsers"))
                              ),
                            ]),
                            _c("b-form-input", {
                              staticClass: "form__input",
                              attrs: { disabled: "", type: "text" },
                              model: {
                                value: _vm.lookalikeSegment.userCount,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.lookalikeSegment,
                                    "userCount",
                                    $$v
                                  )
                                },
                                expression: "lookalikeSegment.userCount",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          {
                            staticClass: "input__wrapper pl-0",
                            attrs: { lg: "6" },
                          },
                          [
                            _c("label", { staticClass: "mr-1" }, [
                              _vm._v(_vm._s(_vm.$t("lookalike.seedSegment"))),
                            ]),
                            _c("b-form-input", {
                              staticClass: "form__input",
                              attrs: { disabled: "", type: "text" },
                              model: {
                                value: _vm.lookalikeSegment.seedSegmentName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.lookalikeSegment,
                                    "seedSegmentName",
                                    $$v
                                  )
                                },
                                expression: "lookalikeSegment.seedSegmentName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c("div", { staticClass: "container__wrapper" }, [
                  _c("div", { staticClass: "container__header" }, [
                    _c("h5", { staticClass: "container__header-title" }, [
                      _vm._v(_vm._s(_vm.$t("Integrations"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "container__integrations" },
                    _vm._l(
                      _vm.lookalikeSegment.activations,
                      function (activation) {
                        return _c("IntegrationCard", {
                          key: activation.id,
                          attrs: { activation: activation },
                          on: {
                            "state-change": function ($event) {
                              return _vm.handleFormInput(_vm.formState)
                            },
                          },
                        })
                      }
                    ),
                    1
                  ),
                ]),
              ]),
              _c("Footer", {
                attrs: {
                  "is-loading": _vm.formState.isLoading,
                  submitted: _vm.formState.isLoading,
                  "cancel-id": `cancel-lookalike-create`,
                  "create-id": `add-lookalike-segment`,
                  "edit-mode": "",
                },
                on: {
                  back: function ($event) {
                    return _vm.cancel()
                  },
                  create: function ($event) {
                    return _vm.triggerSave()
                  },
                },
              }),
            ],
            1
          ),
          _c("SaveChangesModal", {
            attrs: { type: _vm.$t("lookalike.lookalikeSegment") },
            on: {
              save: function ($event) {
                return _vm.updateSegment()
              },
            },
          }),
        ],
        1
      )
    : _c("div", { staticClass: "loading__container" }, [_c("LoadingBar")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }