import axios from '@/libs/axios'
import { API_BASE_URL } from '@/utils/constants'

const lookalike = {
  addNew: (organizationId = '', segmentId = -1) =>
    axios.post(`${API_BASE_URL}/api/v1/${organizationId}/models`, {
      segmentId,
    }),
  removeModel: (organizationId = '', modelId = '') =>
    axios.delete(
      `${API_BASE_URL}/api/v1/${organizationId}/lookalike/models/${modelId}`
    ),
  createSegment: (organizationId = '', modelId = -1, modelSimilarityId = -1) =>
    axios.post(`${API_BASE_URL}/api/v1/${organizationId}/lookalike/segments`, {
      modelId,
      modelSimilarityId,
    }),
  updateSegment: (
    organizationId = '',
    segmentId = '',
    segmentName = '',
    activationIds
  ) =>
    axios.patch(
      `${API_BASE_URL}/api/v1/${organizationId}/lookalike/segments/${segmentId}`,
      {
        segmentId,
        segmentName,
        activationIds,
      }
    ),
  remove: (organizationId = '', segmentId = '') =>
    axios.delete(
      `${API_BASE_URL}/api/v1/${organizationId}/lookalike/segments/${segmentId}`
    ),
  rerun: (organizationId = '', modelId = '') =>
    axios.post(`${API_BASE_URL}/api/v1/${organizationId}/models/rerun`, {
      id: modelId,
    }),
}

export { lookalike }
