// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
      ************ MEDIA QUERY MANAGER ******************

   0 - 576px:         Small devices (landscape phones, 576px and up)
   576px - 768px:     Medium devices (tablets, 768px and up)
   768px - 992px:     Large devices (desktops, 992px and up)
   992px - 1200px:    Big devices (large desktops, 1200px and up)
   1200px - *:        Extra large devices

*/
.bisko-app__lookalike section {
  display: flex;
  flex-direction: column;
}

.bisko-app__lookalike .container__wrapper {
  flex: 1 0 auto;
}

.bisko-app__lookalike .container__wrapper {
  background: #ffffff;
  border-radius: 4px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.bisko-app__lookalike .input__wrapper {
  padding-bottom: 15px;
}
.bisko-app__lookalike .input__wrapper label {
  font-size: 14px;
  line-height: 18px;
  color: #667c99;
  font-weight: 400;
}
.bisko-app__lookalike .input__wrapper select {
  font-size: 14px;
  line-height: 18px;
  color: #a3b0c2;
}
.bisko-app__lookalike .input__wrapper textarea {
  border-color: #e0e5eb;
}
.bisko-app__lookalike .input__wrapper .vs--disabled .vs__dropdown-toggle,
.bisko-app__lookalike .input__wrapper .vs--disabled .vs__clear,
.bisko-app__lookalike .input__wrapper .vs--disabled .vs__search,
.bisko-app__lookalike .input__wrapper .vs--disabled .vs__selected,
.bisko-app__lookalike .input__wrapper .vs--disabled .vs__open-indicator {
  background-color: #efefef !important;
}
.bisko-app__lookalike .input__wrapper ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #a3b0c2;
  font-size: 14px;
  line-height: 18px;
}
.bisko-app__lookalike .input__wrapper ::-moz-placeholder {
  /* Firefox 19+ */
  color: #a3b0c2;
  font-size: 14px;
  line-height: 18px;
}
.bisko-app__lookalike .input__wrapper :-ms-input-placeholder {
  /* IE 10+ */
  color: #a3b0c2;
  font-size: 14px;
  line-height: 18px;
}
.bisko-app__lookalike .input__wrapper :-moz-placeholder {
  /* Firefox 18- */
  color: #a3b0c2;
  font-size: 14px;
  line-height: 18px;
}

.bisko-app__lookalike .container__wrapper--height {
  height: 100%;
  position: relative;
}

.create-lookalike__modal .vs__dropdown-toggle {
  border-color: #e0e5eb !important;
}
.create-lookalike__modal .v-select.vs--single.vs--searchable::after {
  display: none;
}
.create-lookalike__modal .vs__actions .vs__clear {
  margin-right: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: #6b809c;
}
.create-lookalike__modal .vs__actions .vs__clear[disabled=disabled] {
  display: none;
}
.create-lookalike__modal .v-select .vs__dropdown-toggle .vs__actions > svg {
  margin: auto 9.5px !important;
  margin-top: 10px !important;
}
.create-lookalike__modal .v-select .vs__search::-moz-placeholder {
  color: #a3b0c2;
}
.create-lookalike__modal .v-select .vs__search::placeholder {
  color: #a3b0c2;
}

.bisko-app__lookalike .documentation__info-text,
.create-lookalike__modal .documentation__info-text {
  font-size: 13px;
  line-height: 18px;
  color: #7483a1;
  display: flex;
  align-items: center;
  text-align: left;
}
.bisko-app__lookalike .documentation__info-text a,
.create-lookalike__modal .documentation__info-text a {
  color: #052d61;
  text-decoration: underline;
}
.bisko-app__lookalike .documentation__info-text a:hover,
.create-lookalike__modal .documentation__info-text a:hover {
  color: #052d61;
}
.bisko-app__lookalike .documentation__info-text svg,
.create-lookalike__modal .documentation__info-text svg {
  min-width: 18px;
}

.bisko-app__lookalike .dropdown .dropdown-menu .dropdown-item:active {
  background-color: rgba(29, 121, 242, 0.12);
}

.bisko-app__lookalike .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  font-size: 14px;
  text-align: center;
}

.bisko-app__lookalike .loading__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
