var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.data.column.field === "name"
        ? _c("span", { staticClass: "dark-gray-color" }, [
            _c(
              "div",
              { staticClass: "name__wrapper" },
              [
                _c("IconHeading", {
                  attrs: { data: _vm.data, id: _vm.data.row.id },
                }),
                _c("ReadMore", {
                  attrs: {
                    id: `lookalike-name-${_vm.data.row.status}`,
                    classes: _vm.getNameClasses(_vm.data.row.status),
                    text: _vm.data.row.name,
                    to:
                      _vm.data.row.status === "Done" ||
                      _vm.data.row.status === "Reprocessing"
                        ? _vm.createNewSegment
                        : null,
                    "click-handler":
                      _vm.data.row.status === "Processing" ||
                      _vm.data.row.status === "Scheduled"
                        ? () => _vm.$bvModal.show("modal-lookalike-info-show")
                        : () => {},
                  },
                }),
              ],
              1
            ),
          ])
        : _vm.data.column.field === "description"
        ? _c(
            "span",
            [_c("ReadMore", { attrs: { text: _vm.data.row.description } })],
            1
          )
        : _vm.data.column.field === "insertDateTime"
        ? _c(
            "div",
            { staticClass: "lookalike-model__date" },
            [_c("DateColumn", { attrs: { data: _vm.data } })],
            1
          )
        : _vm.data.column.field === "actions"
        ? _c("DropdownActions", {
            attrs: { id: "lookalike-actions", items: _vm.dropdownActions },
          })
        : _c("span", [
            _vm._v(
              " " + _vm._s(_vm.data.formattedRow[_vm.data.column.field]) + " "
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }