export default [
  {
    path: '/bisko/:organizationId/lookalike',
    redirect: '/bisko/:organizationId/lookalike/segments'
  },
  {
    path: '/bisko/:organizationId/lookalike/segments',
    name: 'lookalike-segments',
    component: () => import('@/views/segments/Segments.vue'),
    meta: {
      navActiveLink: 'lookalike-segments',
      pageTitle: 'Lookalike',
      breadcrumb: [
        {
          text: 'Lookalike'
        },
        {
          text: 'Segments',
          active: true
        }
      ]
    }
  },
  {
    path: '/bisko/:organizationId/lookalike/segments/:modelId/create',
    name: 'create-lookalike-segment',
    component: () => import('@/views/segments/CreateModel.vue'),
    meta: {
      navActiveLink: 'lookalike-segments',
      pageTitle: 'Lookalike',
      breadcrumb: [
        {
          text: 'Lookalike'
        },
        {
          text: 'Segments',
          to: '/segments'
        },
        {
          text: 'Create Segment',
          active: true
        }
      ]
    }
  },
  {
    path: '/bisko/:organizationId/lookalike/segments/:modelId/edit',
    name: 'edit-lookalike-segment',
    component: () => import('@/views/segments/EditLookalikeSegment.vue'),
    meta: {
      navActiveLink: 'lookalike-segments',
      pageTitle: 'Lookalike',
      breadcrumb: [
        {
          text: 'Lookalike'
        },
        {
          text: 'Segments',
          to: '/segments'
        },
        {
          text: 'Edit Segment',
          active: true
        }
      ]
    }
  }
]
