var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "lookalike-create-model" }, [
    !_vm.isLoading
      ? _c(
          "div",
          { staticClass: "lookalike-screen__container" },
          [
            _c("div", [
              _c(
                "div",
                { staticClass: "lookalike__header lookalike__header--margin" },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("lookalike.segmentInformation"))),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "lookalike-create-model__segment-name" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Segment Name",
                        "label-for": "Segment Name",
                      },
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "modelName",
                          value: _vm.modelName,
                          readonly: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "lookalike__name-information" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("lookalike.nameOfSeedSegment"))),
                ]),
              ]),
              _c("div", { staticClass: "lookalike__header" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("lookalike.chooseReachAndSimilarity")) + " *"
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "scatter-chart" },
                [
                  _c("span", { staticClass: "echart-reach" }, [
                    _vm._v(_vm._s(_vm.$t("lookalike.reach")) + " "),
                  ]),
                  _c("vue-apex-charts", {
                    key: _vm.chartKey,
                    attrs: {
                      options: _vm.modelSimilarities,
                      series: _vm.modelSimilarities.series,
                      height: "350",
                    },
                  }),
                  _c("span", { staticClass: "echart-similarity" }, [
                    _vm._v(_vm._s(_vm.$t("lookalike.similarity"))),
                  ]),
                ],
                1
              ),
            ]),
            _c("Footer", {
              attrs: {
                "is-loading": _vm.isLoading,
                submitted: _vm.isLoading,
                "cancel-id": `cancel-create-lookalike`,
                "create-id": `add-lookalike`,
                "disable-button": !_vm.canCreateModel || !_vm.canEdit,
              },
              on: {
                back: function ($event) {
                  return _vm.cancel()
                },
                create: function ($event) {
                  _vm.canEdit && _vm.canCreateModel ? _vm.createSegment() : {}
                },
              },
              scopedSlots: _vm._u(
                [
                  !_vm.canEdit
                    ? {
                        key: "create-tooltip",
                        fn: function () {
                          return [
                            _c(
                              "b-tooltip",
                              { attrs: { target: "add-lookalike-wrapper" } },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("permissionMessage"))),
                                ]),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            }),
          ],
          1
        )
      : _c("div", { staticClass: "loading__container" }, [_c("LoadingBar")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }