import axiosIns from '@/libs/axios'
import { API_BASE_URL } from '@/utils/constants'
import {
  destructJSONObject,
  formatFilterQuery,
  formatRequestQuery,
  prepareTableFilters,
} from '@nodus/utilities-front'

export default {
  namespaced: true,
  state: {
    table: {
      columns: [
        {
          label: 'Name',
          field: 'lookalikeSegmentName',
          width: '24%',
        },
        {
          label: 'Code',
          field: 'id',
          width: '15%',
        },
        {
          label: 'Similarity',
          field: 'similarity',
          width: '15%',
        },
        {
          label: 'Reach',
          field: 'reach',
          width: '15%',
        },
        {
          label: 'Integrations',
          field: 'integrations',
          sortable: false,
          thClass: 'not-sortable-headers',
          width: '24%',
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          thClass: 'not-sortable-headers',
          width: '7%',
        },
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      page: 1,
      perPage: 10,
      orderField: '',
      orderType: '',
      filters: [],
    },
    lookalikeFilters: [],
    IDtoDelete: -1,
    segment: [],
    isLookalikeEngineSetSegment: false,
  },
  getters: {
    getSegments: (state) => state.table,
    lookalikeFilters: (state) => state.lookalikeFilters,
    IdtoDelete: (state) => state.IDtoDelete,
    getSegment: (state) => state.segment,
    isLookalikeEngineSet: (state) => state.isLookalikeEngineSetSegment,
  },
  actions: {
    async getSegments({ commit, state }, { id }) {
      const queryParamString = formatRequestQuery(
        state.table.searchTerm,
        state.table.page,
        state.table.perPage,
        state.table.orderField,
        state.table.orderType
      )
      const filterQuery = formatFilterQuery(
        state.lookalikeFilters,
        state.table.filters
      )
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v2/${id}/lookalike/segments?${queryParamString}${filterQuery}`
      )
      commit('SET_SEGMENTS', destructJSONObject(response, 'lookalikeSegments'))
      commit(
        'SET_LOOKALIKE_ENGINE',
        destructJSONObject(response, 'isLookalikeSegmentEngineConfigured')
      )
      commit('SET_TOTAL_RECORDS', destructJSONObject(response, 'totalCount'))
    },
    async getLookalikeFilters({ commit }, { organizationId }) {
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${organizationId}/lookalike/segments/filters`
      )

      commit('SET_LOOKALIKE_FILTERS', {
        tableFilters: destructJSONObject(response, 'filters'),
        organizationId,
      })
    },
    async getSegment({ commit }, { id, segmentId }) {
      const { data } = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/lookalike/segments/${segmentId}`
      )
      commit('SET_SEGMENT', data.data)
    },
    async getSegmentsFile({ state }, { id, format }) {
      const queryParamString = formatRequestQuery(
        state.table.searchTerm,
        state.table.page,
        state.table.perPage,
        state.table.orderField,
        state.table.orderType
      )
      const filterQuery = formatFilterQuery(
        state.lookalikeFilters,
        state.table.filters
      )
      return axiosIns
        .get(
          `${API_BASE_URL}/api/v1/${id}/lookalike/segments/details/file?FileDownloadFormat=${format}&${queryParamString}${filterQuery}`,
          { responseType: 'blob' }
        )
        .then((response) => response.data)
    },
  },
  mutations: {
    SET_SEGMENTS(state, lookalikeSegments) {
      state.table.rows = lookalikeSegments
    },
    SET_ID_TO_DELETE(state, id) {
      state.IDtoDelete = id
    },
    SET_SEGMENT(state, response) {
      state.segment = {
        ...response,
        lookalikeSegmentName: {
          value: response.lookalikeSegmentName,
          errors: [],
        },
      }
    },
    SET_LOOKALIKE_ENGINE(state, lookalikeEngine) {
      state.isLookalikeEngineSetSegment = lookalikeEngine
    },
    SET_TOTAL_RECORDS(state, totalRecords) {
      state.table.totalRecords = totalRecords
    },
    SET_TABLE_PAGE(state, page) {
      state.table.page = page
    },
    SET_LOOKALIKE_FILTERS(state, { tableFilters, organizationId }) {
      const storageKey = `lookalikeFilters-${organizationId}`
      const localFilters = JSON.parse(localStorage.getItem(storageKey) || '[]')
      const { filters, appliedFilters } = prepareTableFilters(
        tableFilters,
        localFilters
      )

      localStorage.setItem(storageKey, JSON.stringify(appliedFilters))
      state.table.filters = appliedFilters
      state.lookalikeFilters = filters
    },
    APPLY_LOOKALIKE_FILTERS(state, filters) {
      state.table.filters = filters
    },
  },
}
