// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bisko-app__lookalike .container__content-lookalike {
  padding-top: 16px;
}
.bisko-app__lookalike .form__input {
  font-size: 14px;
}
.bisko-app__lookalike .form__input .form-control:disabled .form-control[readonly] {
  background-color: #f6f7f9 !important;
}
.bisko-app__lookalike .full__height {
  height: 100%;
}
.bisko-app__lookalike .lookalike-segment__container {
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  min-width: 1117px;
  max-width: 1117px;
  min-height: calc(100vh - 120px);
}
@media (max-width: 1440px) {
.bisko-app__lookalike .lookalike-segment__container {
    width: 100%;
    min-width: auto;
}
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
