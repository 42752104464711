import Vue from 'vue'
import VueRouter from 'vue-router'

import models from './models'
import segments from './segments'

Vue.use(VueRouter)

const routes = [
  {
    path: '/bisko/:organizationId/lookalike',
    redirect: '/bisko/:organizationId/lookalike/models'
  },
  ...models,
  ...segments,
  {
    path: '/bisko/:organizationId/lookalike/:pathMatch(.*)*',
    redirect: '/error/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
