<template>
  <section v-if="!isLoading">
    <div class="lookalike-segment__container">
      <div>
        <div class="container__wrapper container__wrapper--height">
          <div class="container__header">
            <h5 class="container__header-title">
              {{ $t('lookalike.segmentProperties') }}
            </h5>
          </div>
          <div class="container__content-lookalike">
            <b-col lg="6" class="input__wrapper pl-0">
              <label class="mr-1">{{ $t('lookalike.segmentName') }} *</label>
              <b-form-input
                id="lookalike__segment-name"
                v-model.trim="lookalikeSegment.lookalikeSegmentName.value"
                :placeholder="$t('lookalike.enterLookalikeSegmentName')"
                type="text"
                class="form__input"
                :class="{
                  'form__input--invalid':
                    lookalikeSegment.lookalikeSegmentName.errors.length > 0,
                }"
                @input="
                  handleFormInput(formState, [
                    lookalikeSegment.lookalikeSegmentName,
                  ])
                "
              />
              <div
                v-for="(error, errorIndex) in lookalikeSegment
                  .lookalikeSegmentName.errors"
                :key="errorIndex"
                class="error__info-message"
              >
                <span>{{ error }}</span>
              </div>
            </b-col>
            <b-col lg="6" class="input__wrapper pl-0">
              <label class="mr-1">{{ $t('audience.code') }}</label>
              <b-form-input
                v-model="lookalikeSegment.code"
                disabled
                type="text"
                class="form__input"
              />
            </b-col>
            <b-col lg="6" class="input__wrapper pl-0">
              <label class="mr-1">{{ $t('lookalike.predictedUsers') }}</label>
              <b-form-input
                v-model="lookalikeSegment.userCount"
                disabled
                type="text"
                class="form__input"
              />
            </b-col>
            <b-col lg="6" class="input__wrapper pl-0">
              <label class="mr-1">{{ $t('lookalike.seedSegment') }}</label>
              <b-form-input
                v-model="lookalikeSegment.seedSegmentName"
                disabled
                type="text"
                class="form__input"
              />
            </b-col>
          </div>
        </div>
        <div class="container__wrapper">
          <div class="container__header">
            <h5 class="container__header-title">{{ $t('Integrations') }}</h5>
          </div>
          <div class="container__integrations">
            <IntegrationCard
              v-for="activation in lookalikeSegment.activations"
              :key="activation.id"
              :activation="activation"
              @state-change="handleFormInput(formState)"
            />
          </div>
        </div>
      </div>

      <Footer
        :is-loading="formState.isLoading"
        :submitted="formState.isLoading"
        :cancel-id="`cancel-lookalike-create`"
        :create-id="`add-lookalike-segment`"
        edit-mode
        @back="cancel()"
        @create="triggerSave()"
      />
    </div>

    <SaveChangesModal
      :type="$t('lookalike.lookalikeSegment')"
      @save="updateSegment()"
    />
  </section>
  <div v-else class="loading__container">
    <LoadingBar />
  </div>
</template>

<script>
import { lookalike } from '@/api'
import router from '@/router'
import store from '@/store'
import {
  // utility components
  Footer,
  IntegrationCard,
  LoadingBar,
  SaveChangesModal,

  // utility functions
  confirmChanges,
  handleFormInput,
  scrollToElement,
} from '@nodus/utilities-front'
import { BCol, BFormInput } from 'bootstrap-vue'
import { computed, getCurrentInstance, onBeforeMount, reactive, ref } from 'vue'

export default {
  name: 'EditLookalikeSegment',
  components: {
    LoadingBar,
    BCol,
    BFormInput,
    IntegrationCard,
    Footer,
    SaveChangesModal,
  },
  async beforeRouteLeave(to, from, next) {
    if (!this.formState.isSubmitted && !this.formState.isPristine) {
      const response = await confirmChanges(this.$bvModal)
      next(response)
    } else {
      next()
    }
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId, modelId } = vm.$route.params

    const formState = reactive({
      isSubmitted: false,
      isLoading: false,
      isPristine: true,
    })
    const isLoading = ref(false)
    const lookalikeSegment = computed(
      () => store.getters['segments/getSegment']
    )

    onBeforeMount(async () => {
      isLoading.value = true
      await store.dispatch('segments/getSegment', {
        id: organizationId,
        segmentId: modelId,
      })
      if (!lookalikeSegment.value.canEdit) router.push('/error/401')
      isLoading.value = false
    })

    const updateSegment = async () => {
      formState.isLoading = true

      const response = await lookalike.updateSegment(
        organizationId,
        modelId,
        lookalikeSegment.value.lookalikeSegmentName.value,
        lookalikeSegment.value.activations
          .filter((a) => a.enabled)
          .map((a) => a.id)
      )

      const { errors, success } = response.data

      if (!success) {
        lookalikeSegment.value.lookalikeSegmentName.errors = errors.segmentName
        setTimeout(
          () => scrollToElement('[class*="form__input--invalid"]'),
          200
        )
      } else {
        formState.isSubmitted = true
        router.push({
          name: 'lookalike-segments',
          params: { organizationId },
        })
      }
      formState.isLoading = false
    }

    const cancel = () => {
      router.push({
        name: 'lookalike-segments',
        params: { organizationId },
      })
    }

    const triggerSave = () => {
      if (formState.isPristine) cancel()
      else vm.$bvModal.show('save-changes-modal')
    }

    return {
      organizationId,
      lookalikeSegment,
      updateSegment,
      cancel,
      formState,
      isLoading,
      handleFormInput,
      triggerSave,
    }
  },
}
</script>

<style lang="scss">
.container__content-lookalike {
  padding-top: 16px;
}

.form__input {
  font-size: 14px;
}

.form__input .form-control:disabled .form-control[readonly] {
  background-color: #f6f7f9 !important;
}

.full__height {
  height: 100%;
}

.lookalike-segment__container {
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  min-width: 1117px;
  max-width: 1117px;
  min-height: calc(100vh - 120px);

  @media (max-width: 1440px) {
    width: 100%;
    min-width: auto;
  }
}
</style>
