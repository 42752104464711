var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { class: { blur: !_vm.isLookalikeEngineSet && !_vm.isLoading } },
      [
        _c(
          "div",
          { staticClass: "lookalike-segments__header" },
          [
            _c("ExportButton", {
              attrs: {
                id: "export-lookalike-segments",
                "is-loading": _vm.isLoading,
                "get-file": _vm.getFile,
                exporting: _vm.exporting,
                "has-data": _vm.lookalikeSegments.totalRecords > 0,
              },
            }),
            _c("FilterList", {
              ref: "filterLookalikes",
              attrs: {
                filters: _vm.lookalikeFilters,
                "applied-filters": _vm.lookalikeSegments.filters,
                loading: _vm.filtersLoading,
                "has-data": _vm.lookalikeSegments.totalRecords > 0,
              },
              on: { "update-listing": _vm.getData },
            }),
            _c("SearchInputField", {
              attrs: {
                id: "search-lookalike-segments",
                data: _vm.lookalikeSegments,
              },
            }),
          ],
          1
        ),
        _vm.lookalikeSegments.filters.length
          ? _c(
              "div",
              { staticClass: "lookalike-segments__header" },
              [
                _c("ActiveFilters", {
                  attrs: {
                    "applied-filters": _vm.lookalikeSegments.filters,
                    filters: _vm.lookalikeFilters,
                  },
                  on: { "remove-filter": _vm.removeFilter },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "table__wrapper" },
          [
            _c("CustomTable", {
              attrs: {
                data: _vm.lookalikeSegments,
                "type-of-table": "lookalike-segments",
                "is-pagination-enabled": true,
                "is-loading": _vm.isLoading,
              },
              on: {
                "update-table": _vm.getData,
                "clear-filters": _vm.clearFilters,
              },
              scopedSlots: _vm._u([
                {
                  key: "row-data",
                  fn: function ({ data }) {
                    return [
                      _c("LookalikeSegmentsRow", { attrs: { data: data } }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c("DeleteModal", {
          attrs: {
            title: _vm.$t("lookalike.deleteLookalikeSegment"),
            type: _vm.$t("lookalike.lookalikeSegment"),
          },
          on: { delete: _vm.triggerDelete },
        }),
      ],
      1
    ),
    !_vm.isLookalikeEngineSet && !_vm.isLoading
      ? _c("div", { staticClass: "info" }, [
          _c("img", {
            attrs: {
              alt: "Vue logo",
              src: require("@/assets/svgs/i-warning.svg"),
            },
          }),
          _c("span", [_vm._v(_vm._s(_vm.$t("lookalike.notEnableMessage")))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }