import axiosIns from '@/libs/axios'
import store from '@/store'
import { API_BASE_URL } from '@/utils/constants'
import { destructJSONObject, formatRequestQuery } from '@nodus/utilities-front'
import customTooltip from './customTooltip'

export default {
  namespaced: true,
  state: {
    table: {
      columns: [
        {
          label: 'Name',
          field: 'name',
          thClass: 'custom-name-header name-column',
          width: '25%'
        },
        {
          label: 'Description',
          field: 'description',
          thClass: 'description-class',
          width: '30%'
        },
        {
          label: 'Date',
          field: 'insertDateTime',
          thClass: 'custom-data-class',
          width: '38%'
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          thClass: 'not-sortable-headers',
          width: '7%'
        }
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      page: 1,
      perPage: 10,
      orderField: '',
      orderType: ''
    },
    canCreate: false,
    canCreateModel: false,
    isLookalikeEngineSet: false,
    segmentsForLookALike: [],
    modelId: -1,
    modelName: '',
    canEdit: false,
    modelSimilarityId: -1,
    modelSimilarities: {
      series: [
        {
          name: 'Lookalike Models',
          data: []
        }
      ],
      chart: {
        height: 350,
        type: 'scatter',
        fontFamily: 'SFProText',
        foreColor: '#667C99',
        toolbar: {
          show: false
        },
        zoom: {
          enabled: false
        },
        events: {
          dataPointSelection(event, ctx, cfg) {
            const modelInfo =
              cfg.w.config.series[cfg.seriesIndex].data[cfg.dataPointIndex].info
            const selected = cfg.selectedDataPoints[0]

            if (modelInfo.isUsed) {
              if (selected.length > 0)
                ctx.toggleDataPointSelection(
                  cfg.seriesIndex,
                  cfg.dataPointIndex
                ) // reverts selection
            }
            store.commit(
              'models/SET_SIMILARITY_ID',
              selected.length > 0 ? modelInfo.id : -1
            )
          }
        }
      },
      tooltip: {
        enabled: true,
        style: {
          fontFamily: 'SFProText',
          fontSize: '14px'
        },
        custom: ({ series, seriesIndex, dataPointIndex, w }) =>
          customTooltip({ series, seriesIndex, dataPointIndex, w })
      },
      markers: {
        size: 8
      },
      states: {
        hover: {
          filter: {
            type: 'none'
          }
        },
        active: {
          filter: {
            type: 'none'
          }
        }
      },
      grid: {
        borderColor: '#E0E5EB',
        padding: {
          left: 20
        },
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      xaxis: {
        tickAmount: 'dataPoints',
        tickPlacement: 'on',
        labels: {
          formatter: (val) => `${val}%`
        }
      },
      yaxis: {
        tickAmount: 7,
        labels: {
          formatter: (series) => {
            if (series < 1e3) return series
            if (series >= 1e3 && series < 1e6)
              return `${+(series / 1e3).toFixed(1)}K`
            if (series >= 1e6 && series < 1e9)
              return `${parseFloat(series / 1e6).toFixed(1)}M`
            if (series >= 1e9 && series < 1e12)
              return `${+(series / 1e9).toFixed(1)}B`
            return series
          }
        }
      }
    }
  },
  getters: {
    lookalikeModels: (state) => state.table,
    canCreateLookLike: (state) => state.canCreate,
    getSeedSegmentsForLookalike: (state) => state.segmentsForLookALike,
    getSimilarities: (state) => state.modelSimilarities,
    getName: (state) => state.modelName,
    getCanEdit: (state) => state.canEdit,
    getId: (state) => state.modelId,
    getCanCreate: (state) => state.canCreateModel,
    getSimilarityId: (state) => state.modelSimilarityId,
    isLookalikeEngineSet: (state) => state.isLookalikeEngineSet
  },
  actions: {
    async getLookalikeModels({ commit, state }, { id }) {
      const queryParamString = formatRequestQuery(
        state.table.searchTerm,
        state.table.page,
        state.table.perPage,
        state.table.orderField,
        state.table.orderType
      )
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v2/${id}/models?${queryParamString}`
      )

      commit('SET_LOOKALIKE', destructJSONObject(response, 'models'))
      commit('SET_TOTAL_RECORDS', destructJSONObject(response, 'totalCount'))
      commit('CAN_CREATE_LOOKALIKE', destructJSONObject(response, 'canCreate'))
      commit(
        'SET_LOOKALIKE_ENGINE',
        destructJSONObject(response, 'isLookalikeModelEngineConfigured')
      )
    },
    async getSeedSegmentsForLookalike({ commit }, { id }) {
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/segments/seed`
      )
      commit(
        'SET_SEGMENTS_FOR_LOOKALIKE',
        destructJSONObject(response, 'basicSegments')
      )
    },
    async getSimilarities({ commit }, { id, modelId }) {
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/${id}/models/${modelId}`
      )

      commit(
        'SET_SIMILARITIES',
        destructJSONObject(response, 'modelSimilarities')
      )
      commit('SET_NAME', destructJSONObject(response, 'modelName'))
      commit('SET_ID', destructJSONObject(response, 'modelId'))
      commit('SET_CAN_EDIT', destructJSONObject(response, 'canEdit'))
    }
  },
  mutations: {
    SET_LOOKALIKE(state, segments) {
      state.table.rows = segments
    },
    CAN_CREATE_LOOKALIKE(state, create) {
      state.canCreate = create
    },
    SET_LOOKALIKE_ENGINE(state, lookalikeEngine) {
      state.isLookalikeEngineSet = lookalikeEngine
    },
    SET_SEGMENTS_FOR_LOOKALIKE(state, segmentsForLookALike) {
      state.segmentsForLookALike = segmentsForLookALike
    },
    SET_SIMILARITIES(state, similarities) {
      state.modelSimilarities.series[0].data = similarities.reduce(
        (acc, { similarity, reach, id, isUsed }) => {
          acc.push({
            x: +similarity,
            y: reach,
            info: { id, isUsed },
            fillColor: isUsed ? 'disabled' : '#1d79f2'
          })
          return acc
        },
        []
      )
    },
    SET_NAME(state, name) {
      state.modelName = name
    },
    SET_CAN_EDIT(state, canEdit) {
      state.canEdit = canEdit
    },
    SET_ID(state, id) {
      state.modelId = id
    },
    SET_SIMILARITY_ID(state, id) {
      state.canCreateModel = id !== -1
      state.modelSimilarityId = id
    },
    SET_TOTAL_RECORDS(state, totalRecords) {
      state.table.totalRecords = totalRecords
    }
  }
}
