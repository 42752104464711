// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.bisko-app__lookalike .done-text {
  display: flex;
  align-items: center;
}
.bisko-app__lookalike .done-text::before {
  content: "";
  width: 6px;
  height: 6px;
  background: #36b37e;
  border-radius: 20px;
  margin-right: 8px;
}
.bisko-app__lookalike .processing-text {
  display: flex;
  align-items: center;
}
.bisko-app__lookalike .processing-text::before {
  content: "";
  width: 6px;
  height: 6px;
  background: #1d79f2;
  border-radius: 20px;
  margin-right: 8px;
}
.bisko-app__lookalike .processing-text-link span {
  opacity: 0.7;
}
.bisko-app__lookalike .default-cursor span {
  cursor: default;
}
.bisko-app__lookalike .segment__state-icon {
  margin-right: 10px;
}
@keyframes animate_p1 {
to {
    stroke-dasharray: 23.5 314;
} /* 47.1 ÷ 4 = 7.85 */
}
.bisko-app__lookalike #bg {
  fill: white;
  stroke-dasharray: 47.5 50.5;
}
.bisko-app__lookalike #p1 {
  stroke-dasharray: 0 31.4; /* 2π × 50 ≈ 31.4 */
  animation: animate_p1 2.5s linear forwards infinite;
}
.bisko-app__lookalike .svg__processing {
  position: relative;
  margin-left: -3px;
}
.bisko-app__lookalike .svg__border {
  border-left: 1.5px solid #1d79f2;
  border-bottom: 1.5px solid #1d79f2;
  position: absolute;
  left: 15px;
  width: 9px;
  height: 9px;
  top: 7px;
}
.bisko-app__lookalike .svg__processing-icon {
  position: absolute;
  bottom: 17px;
  left: 19px;
}
.bisko-app__lookalike .lookalike-model__date {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.bisko-app__lookalike .created-by {
  display: block;
  margin-bottom: 8px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
