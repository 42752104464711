var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.data.column.field === "similarity"
      ? _c("span", [_vm._v(" " + _vm._s(_vm.data.row.similarity) + " % ")])
      : _vm.data.column.field === "lookalikeSegmentName"
      ? _c(
          "span",
          [
            _c("ReadMore", {
              attrs: { text: _vm.data.row.lookalikeSegmentName },
            }),
          ],
          1
        )
      : _vm.data.column.field === "reach"
      ? _c("span", [
          _vm._v(" " + _vm._s(_vm.data.row.reach.toLocaleString()) + " "),
        ])
      : _vm.data.column.field === "integrations"
      ? _c("span", [
          _c(
            "div",
            { staticClass: "table-list-integrations-container" },
            [
              _c("IntegrationColumn", {
                attrs: {
                  "row-id": _vm.data.row.id,
                  activations: _vm.data.row.activations,
                },
              }),
            ],
            1
          ),
        ])
      : _vm.data.column.field === "actions"
      ? _c(
          "div",
          { staticClass: "table-list-actions-container" },
          [
            _c("DropdownActions", {
              attrs: {
                id: "lookalike-segment-actions",
                items: _vm.dropdownActions,
              },
            }),
          ],
          1
        )
      : _c("span", [
          _vm._v(
            " " + _vm._s(_vm.data.formattedRow[_vm.data.column.field]) + " "
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }