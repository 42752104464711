<script>
import { lookalike } from '@/api'
import store from '@/store'
import {
  // utility components
  DateColumn,
  DropdownActions,
  IconHeading,
  ReadMore,
  // utility functions
  formatDate,
  isEmail,
} from '@nodus/utilities-front'
import { getCurrentInstance } from 'vue'

export default {
  props: {
    data: {
      type: [Array, Object],
    },
  },
  components: {
    DropdownActions,
    ReadMore,
    DateColumn,
    IconHeading,
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params

    const isEmailValid = (email) => isEmail(email)

    const rerun = async (model) => {
      await lookalike.rerun(organizationId, model.id)

      store.dispatch('models/getLookalikeModels', { id: organizationId })
      store.dispatch('models/getSeedSegmentsForLookalike', {
        id: organizationId,
      })
    }

    const { id, canReprocess, canDelete, status } = props.data.row

    const createNewSegment = {
      name: 'create-lookalike-segment',
      params: {
        organizationId,
        modelId: id || 1,
      },
    }

    const dropdownActions = [
      ...(props.data.row.status === 'Done' ||
      props.data.row.status === 'Reprocessing'
        ? [
            {
              id: 'create-lookalike-segment',
              icon: 'Plus',
              text: 'Create segment',
              to: createNewSegment,
            },
          ]
        : []),
      {
        id: 'rerurn-lookalike-model',
        icon: 'Refresh',
        text: 'Rerun',
        disabled:
          !canReprocess || status === 'Processing' || status === 'Scheduled',
        action: () => rerun(props.data.row),
      },
      {
        id: 'delete-lookalike-model',
        icon: 'Delete',
        text: 'Delete',
        type: 'danger',
        disabled: !canDelete,
        modal: 'modal-small-delete',
        action: () => vm.$store.commit('models/SET_ID', id),
      },
    ]

    const getNameClasses = (status) => {
      if (status === 'Processing' || status === 'Scheduled')
        return ['processing-text-link']
      else if (status === 'Failed') return ['default-cursor']
      else return []
    }

    return {
      organizationId,
      formatDate,
      isEmailValid,
      rerun,
      dropdownActions,
      createNewSegment,
      getNameClasses,
    }
  },
}
</script>
<template>
  <div>
    <!-- Column: Name -->
    <span v-if="data.column.field === 'name'" class="dark-gray-color">
      <div class="name__wrapper">
        <IconHeading :data="data" :id="data.row.id"> </IconHeading>
        <ReadMore
          :id="`lookalike-name-${data.row.status}`"
          :classes="getNameClasses(data.row.status)"
          :text="data.row.name"
          :to="
            data.row.status === 'Done' || data.row.status === 'Reprocessing'
              ? createNewSegment
              : null
          "
          :click-handler="
            data.row.status === 'Processing' || data.row.status === 'Scheduled'
              ? () => $bvModal.show('modal-lookalike-info-show')
              : () => {}
          "
        />
      </div>
    </span>

    <!-- Column: Description -->
    <span v-else-if="data.column.field === 'description'">
      <ReadMore :text="data.row.description" />
    </span>

    <!-- Column: Create and Update Date -->
    <div
      v-else-if="data.column.field === 'insertDateTime'"
      class="lookalike-model__date"
    >
      <DateColumn :data="data"></DateColumn>
    </div>

    <!-- Column: Actions -->
    <DropdownActions
      v-else-if="data.column.field === 'actions'"
      :id="'lookalike-actions'"
      :items="dropdownActions"
    />

    <!-- Column: Common -->
    <span v-else>
      {{ data.formattedRow[data.column.field] }}
    </span>
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/views/lookalike/lookalike-data.scss';
</style>
