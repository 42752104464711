// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bisko-app__lookalike .blur[data-v-222c1325] {
  filter: blur(2px);
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.bisko-app__lookalike .lookalike-segments__header[data-v-222c1325] {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 20px;
}
.bisko-app__lookalike .lookalike-segments__header[data-v-222c1325]:nth-child(2) {
  justify-content: flex-start;
}
.bisko-app__lookalike .lookalike-segments__header:nth-child(2) section[data-v-222c1325] {
  flex-direction: row;
}
.bisko-app__lookalike .lookalike-segments__header .table__search[data-v-222c1325] {
  padding: 0;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
