<template>
  <section class="lookalike-create-model">
    <div v-if="!isLoading" class="lookalike-screen__container">
      <div>
        <div class="lookalike__header lookalike__header--margin">
          <span>{{ $t('lookalike.segmentInformation') }}</span>
        </div>
        <div class="lookalike-create-model__segment-name">
          <b-form-group label="Segment Name" label-for="Segment Name">
            <b-form-input id="modelName" :value="modelName" readonly />
          </b-form-group>
        </div>
        <div class="lookalike__name-information">
          <span>{{ $t('lookalike.nameOfSeedSegment') }}</span>
        </div>
        <div class="lookalike__header">
          <span>{{ $t('lookalike.chooseReachAndSimilarity') }} *</span>
        </div>
        <div class="scatter-chart">
          <span class="echart-reach">{{ $t('lookalike.reach') }} </span>
          <vue-apex-charts
            :key="chartKey"
            :options="modelSimilarities"
            :series="modelSimilarities.series"
            height="350"
          />
          <span class="echart-similarity">{{
            $t('lookalike.similarity')
          }}</span>
        </div>
      </div>

      <Footer
        :is-loading="isLoading"
        :submitted="isLoading"
        :cancel-id="`cancel-create-lookalike`"
        :create-id="`add-lookalike`"
        :disable-button="!canCreateModel || !canEdit"
        @back="cancel()"
        @create="canEdit && canCreateModel ? createSegment() : {}"
      >
        <template v-if="!canEdit" v-slot:create-tooltip>
          <b-tooltip target="add-lookalike-wrapper">
            <span>{{ $t('permissionMessage') }}</span>
          </b-tooltip>
        </template>
      </Footer>
    </div>
    <div v-else class="loading__container">
      <LoadingBar />
    </div>
  </section>
</template>

<script>
import { lookalike } from '@/api'
import router from '@/router'
import store from '@/store'
import {
  // utility components
  Footer,
  LoadingBar,
} from '@nodus/utilities-front'
import { BFormGroup, BFormInput, BTooltip } from 'bootstrap-vue'
import {
  computed,
  getCurrentInstance,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRefs,
} from 'vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BTooltip,
    VueApexCharts,
    LoadingBar,
    Footer,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId, modelId } = vm.$route.params

    const state = reactive({
      modalName: 'Segment Name 1',
    })
    const initialChartConfig = {
      series: [
        {
          name: 'Lookalike Models',
          data: [],
        },
      ],
      chart: {
        height: 350,
        type: 'scatter',
      },
    }

    const modelSimilarities = ref(initialChartConfig)
    const modelName = computed(() => store.getters['models/getName'])
    const canEdit = computed(() => store.getters['models/getCanEdit'])
    const modelSimilarityId = computed(
      () => store.getters['models/getSimilarityId']
    )
    const canCreateModel = computed(() => store.getters['models/getCanCreate'])
    const isLoading = ref(true)

    const chartKey = ref('key')

    onMounted(async () => {
      await store
        .dispatch('models/getSimilarities', { id: organizationId, modelId })
        .then(() => {
          modelSimilarities.value = store.getters['models/getSimilarities']
          chartKey.value = 'scatter'
        })
      isLoading.value = false
    })

    onBeforeUnmount(() => store.commit('models/SET_SIMILARITY_ID', -1))

    const createSegment = async () => {
      await lookalike.createSegment(
        organizationId,
        modelId,
        modelSimilarityId.value
      )

      router.push({
        name: 'lookalike-segments',
        params: { organizationId },
      })
    }

    const cancel = () => {
      router.push({
        name: 'lookalike-models',
        params: { organizationId },
      })
    }

    return {
      ...toRefs(state),
      modelSimilarities,
      modelName,
      canEdit,
      modelSimilarityId,
      canCreateModel,
      createSegment,
      cancel,
      isLoading,
      chartKey,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/views/lookalike/index.scss';
</style>
