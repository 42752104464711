import Vue from 'vue'
import Vuex from 'vuex'

import axiosIns from '@/libs/axios'
import { API_BASE_URL } from '@/utils/constants'
import models from './models'
import segments from './segments'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    models,
    segments,
  },
  state: {
    isLookalikeEnabled: '',
  },
  getters: {
    lookalikeEnabled: (state) => state.isLookalikeEnabled,
  },
  actions: {
    async getAiFeatures({ commit }, { id }) {
      const response = await axiosIns.get(
        `${API_BASE_URL}/api/v1/organizations/${id}/configurations/ai`
      )
      commit(
        'SET_LOOKALIKE_ENABLED',
        response.data.data.lookalikeModelingIsEnabled
      )
      return response.data.data
    },
  },
  mutations: {
    SET_LOOKALIKE_ENABLED(state, value) {
      state.isLookalikeEnabled = value
    },
  },
})
