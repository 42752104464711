/* eslint-disable */
const customTooltip = ({series, seriesIndex, dataPointIndex, w}) => {
  const dataPointData = w.config.series[seriesIndex].data[dataPointIndex]
  return `<div class="custom-tooltip ${dataPointData.info.isUsed ? ' disabled' : ''}">
  <div><span>Reach: ${dataPointData.y.toLocaleString()}</span></div>
  <div><span>Similarity: ${dataPointData.x}%</span></div>
  <div class="custom-tooltip__info">
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.790527 8.00065C0.790528 4.04261 3.99915 0.833984 7.95719 0.833984C11.9152 0.833985 15.1239 4.04261 15.1239 8.00065C15.1239 11.9587 11.9152 15.1673 7.95719 15.1673C3.99915 15.1673 0.790527 11.9587 0.790527 8.00065ZM7.95719 4.63399C8.23334 4.63399 8.45719 4.85784 8.45719 5.13399V5.14351C8.45719 5.41965 8.23334 5.64351 7.95719 5.64351C7.68105 5.64351 7.45719 5.41965 7.45719 5.14351V5.13399C7.45719 4.85784 7.68105 4.63399 7.95719 4.63399ZM6.02862 7.52446C6.02862 7.24832 6.25248 7.02446 6.52862 7.02446H8.45719V10.3578H9.38576C9.66191 10.3578 9.88576 10.5817 9.88576 10.8578C9.88576 11.1339 9.66191 11.3578 9.38576 11.3578H6.52862C6.25248 11.3578 6.02862 11.1339 6.02862 10.8578C6.02862 10.5817 6.25248 10.3578 6.52862 10.3578H7.45719V8.02446H6.52862C6.25248 8.02446 6.02862 7.8006 6.02862 7.52446Z" fill="white"></path>
  </svg>
  <span>${dataPointData.info.isUsed ? '<i>Not available!</i>': 'Click to select'}</span>
  </div>
  </div>
  </div>`
}

export default customTooltip