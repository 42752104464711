export default [
  {
    path: '/bisko/:organizationId/lookalike',
    redirect: '/bisko/:organizationId/lookalike/models'
  },
  {
    path: '/bisko/:organizationId/lookalike/models',
    name: 'lookalike-models',
    component: () => import('@/views/models/Lookalikes.vue'),
    meta: {
      navActiveLink: 'lookalike-models',
      pageTitle: 'Lookalike',
      breadcrumb: [
        {
          text: 'Lookalike'
        },
        {
          text: 'Models',
          active: true
        }
      ]
    }
  }
]
