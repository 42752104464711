var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      { class: { blur: !_vm.isLookalikeEngineSet && !_vm.isLoading } },
      [
        _c(
          "div",
          { staticClass: "table-list__header" },
          [
            _c("primary-create-button", {
              attrs: {
                id: "create-lookalike-model",
                name: _vm.$t("lookalike.createNew"),
                "can-add": _vm.canCreateLookalike && _vm.isLookalikeEngineSet,
                "is-loading": _vm.isLoading,
                "click-handler": _vm.openModal,
              },
            }),
            _c("SearchInputField", {
              attrs: { id: "search-loookalikes", data: _vm.lookalikeModels },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "table__wrapper" },
          [
            _c("CustomTable", {
              attrs: {
                data: _vm.lookalikeModels,
                "type-of-table": "lookalike-models",
                "is-pagination-enabled": true,
                "is-loading": _vm.isLoading,
                "empty-state-action": {
                  canAdd: _vm.canCreateLookalike && _vm.isLookalikeEngineSet,
                  action: _vm.openModal,
                },
              },
              on: { "update-table": _vm.getData },
              scopedSlots: _vm._u([
                {
                  key: "row-data",
                  fn: function ({ data }) {
                    return [_c("LookalikesRow", { attrs: { data: data } })]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "user-roles-tables__duplicate-modal" },
          [
            _c(
              "b-modal",
              {
                attrs: {
                  id: "modal-lookalike-info-show",
                  "ok-title": _vm.$t("ok"),
                  "ok-only": "",
                  centered: "",
                  size: "md",
                  title: _vm.$t("lookalike.trainingTitle") + "...",
                  "no-enforce-focus": "",
                  "no-close-on-backdrop": "",
                },
              },
              [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("lookalike.trainingMessage")) + "."),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "user-roles-tables__duplicate-modal" },
          [
            _c(
              "b-modal",
              {
                ref: "createLookalikeModel",
                attrs: {
                  id: "modal-lookalike-create-model",
                  "modal-class": "create-lookalike__modal",
                  "ok-title": _vm.$t("Create"),
                  "cancel-title": _vm.$t("Cancel"),
                  "cancel-variant": "outline-secondary",
                  centered: "",
                  size: "md",
                  title: _vm.$t("lookalike.createNew"),
                  "no-enforce-focus": "",
                  "no-close-on-backdrop": "",
                  "ok-disabled": !_vm.selectedSegment,
                },
                on: {
                  ok: _vm.createSegment,
                  cancel: _vm.onModalClose,
                  close: _vm.onModalClose,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "lookalike-modal__content" },
                  [
                    _c(
                      "div",
                      { staticClass: "documentation__info-text mb-1" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "lookalike.lookalikeModelDocumentationLink"
                              )
                            ) + " "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `${_vm.whitelabel.documentationUrl}/intro#introduction-to-lookalike-models`,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("Documentation").toLowerCase())
                              ),
                            ]
                          ),
                          _vm._v("."),
                        ]),
                      ]
                    ),
                    _c("h6", [_vm._v(_vm._s(_vm.$t("Segment")) + " *")]),
                    _c("v-select", {
                      staticClass: "segment__select",
                      attrs: {
                        id: "select-segment",
                        label: "name",
                        options: _vm.segmentsForLookalike,
                        placeholder: _vm.$t("lookalike.selectFromList"),
                      },
                      model: {
                        value: _vm.selectedSegment,
                        callback: function ($$v) {
                          _vm.selectedSegment = $$v
                        },
                        expression: "selectedSegment",
                      },
                    }),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("lookalike.chooseSeedSegment"))),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _c("DeleteModal", {
          attrs: {
            title: _vm.$t("lookalike.deleteLookalikeModel"),
            type: _vm.$t("Lookalike"),
          },
          on: { delete: _vm.triggerDelete },
        }),
      ],
      1
    ),
    !_vm.isLookalikeEngineSet && !_vm.isLoading
      ? _c("div", { staticClass: "info" }, [
          _c("img", {
            attrs: {
              alt: "Vue logo",
              src: require("@/assets/svgs/i-warning.svg"),
            },
          }),
          _c("span", [_vm._v(_vm._s(_vm.$t("lookalike.notEnableMessage")))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }