<template>
  <div>
    <section :class="{ blur: !isLookalikeEngineSet && !isLoading }">
      <div class="table-list__header">
        <primary-create-button
          id="create-lookalike-model"
          :name="$t('lookalike.createNew')"
          :can-add="canCreateLookalike && isLookalikeEngineSet"
          :is-loading="isLoading"
          :click-handler="openModal"
        />
        <SearchInputField id="search-loookalikes" :data="lookalikeModels" />
      </div>
      <div class="table__wrapper">
        <CustomTable
          :data="lookalikeModels"
          type-of-table="lookalike-models"
          :is-pagination-enabled="true"
          :is-loading="isLoading"
          :empty-state-action="{
            canAdd: canCreateLookalike && isLookalikeEngineSet,
            action: openModal,
          }"
          @update-table="getData"
        >
          <template #row-data="{ data }">
            <LookalikesRow :data="data" />
          </template>
        </CustomTable>
      </div>

      <!-- Lookalike show info Modal -->
      <div class="user-roles-tables__duplicate-modal">
        <b-modal
          id="modal-lookalike-info-show"
          :ok-title="$t('ok')"
          ok-only
          centered
          size="md"
          :title="$t('lookalike.trainingTitle') + '...'"
          no-enforce-focus
          no-close-on-backdrop
        >
          <span>{{ $t('lookalike.trainingMessage') }}.</span>
        </b-modal>
      </div>

      <!-- Create lookalike model Modal -->
      <div class="user-roles-tables__duplicate-modal">
        <b-modal
          id="modal-lookalike-create-model"
          ref="createLookalikeModel"
          modal-class="create-lookalike__modal"
          :ok-title="$t('Create')"
          :cancel-title="$t('Cancel')"
          cancel-variant="outline-secondary"
          centered
          size="md"
          :title="$t('lookalike.createNew')"
          no-enforce-focus
          no-close-on-backdrop
          :ok-disabled="!selectedSegment"
          @ok="createSegment"
          @cancel="onModalClose"
          @close="onModalClose"
        >
          <div class="lookalike-modal__content">
            <div class="documentation__info-text mb-1">
              <span
                >{{ $t('lookalike.lookalikeModelDocumentationLink') }}
                <a
                  :href="`${whitelabel.documentationUrl}/intro#introduction-to-lookalike-models`"
                  target="_blank"
                  >{{ $t('Documentation').toLowerCase() }}</a
                >.</span
              >
            </div>
            <h6>{{ $t('Segment') }} *</h6>
            <v-select
              id="select-segment"
              v-model="selectedSegment"
              class="segment__select"
              label="name"
              :options="segmentsForLookalike"
              :placeholder="$t('lookalike.selectFromList')"
            />
            <p>{{ $t('lookalike.chooseSeedSegment') }}</p>
          </div>
        </b-modal>
      </div>

      <!-- Delete lookalike model Modal -->
      <DeleteModal
        :title="$t('lookalike.deleteLookalikeModel')"
        :type="$t('Lookalike')"
        @delete="triggerDelete"
      />
    </section>
    <div v-if="!isLookalikeEngineSet && !isLoading" class="info">
      <img alt="Vue logo" src="@/assets/svgs/i-warning.svg" />
      <span>{{ $t('lookalike.notEnableMessage') }}</span>
    </div>
  </div>
</template>

<script>
import { lookalike } from '@/api'
import LookalikesRow from '@/components/table-data/LookalikesRow.vue'
import store from '@/store'
import { whitelabel } from '@/utils/constants'
import {
  // utility components
  CustomTable,
  DeleteModal,
  PrimaryCreateButton,
  SearchInputField,
  // utility functions
  handleLastRowDelete,
} from '@nodus/utilities-front'
import { VBModal } from 'bootstrap-vue'
import {
  computed,
  getCurrentInstance,
  onMounted,
  reactive,
  ref,
  toRefs,
} from 'vue'
import vSelect from 'vue-select'

export default {
  directives: {
    'b-modal': VBModal,
  },
  components: {
    vSelect,
    CustomTable,
    PrimaryCreateButton,
    SearchInputField,
    DeleteModal,
    LookalikesRow,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params
    const { addNew, removeModel } = lookalike

    const state = reactive({
      selectedSegment: '',
    })
    const createLookalikeModel = ref('')
    const isLoading = ref(false)

    const lookalikeModels = computed(
      () => store.getters['models/lookalikeModels']
    )
    const canCreateLookalike = computed(
      () => store.getters['models/canCreateLookLike']
    )
    const lookalikeIdToDelete = computed(() => store.getters['models/getId'])

    const isLookalikeEngineSet = computed(() => store.getters.lookalikeEnabled)
    const segmentsForLookalike = computed(() =>
      store.getters['models/getSeedSegmentsForLookalike'].filter(
        (obj) => obj.isEnabled
      )
    )

    onMounted(async () => {
      isLoading.value = true
      await store.dispatch('getAiFeatures', { id: organizationId })
      if (!isLookalikeEngineSet.value) {
        store.commit('models/SET_LOOKALIKE', [])
        isLoading.value = false
        return
      }
      store.dispatch('models/getSeedSegmentsForLookalike', {
        id: organizationId,
      })
      await store.dispatch('models/getLookalikeModels', { id: organizationId })
      isLoading.value = false
    })

    const onModalClose = () => {
      state.selectedSegment = ''
    }

    const createSegment = async () => {
      await addNew(organizationId, state.selectedSegment?.id)
      isLoading.value = true
      store.dispatch('models/getSeedSegmentsForLookalike', {
        id: organizationId,
      })
      await store.dispatch('models/getLookalikeModels', { id: organizationId })
      isLoading.value = false
      onModalClose()
    }

    const getData = async () => {
      isLoading.value = true
      await store.dispatch('models/getLookalikeModels', { id: organizationId })
      isLoading.value = false
    }

    const triggerDelete = async () => {
      handleLastRowDelete(lookalikeModels.value, store, 'models/SET_TABLE_PAGE')
      await removeModel(organizationId, lookalikeIdToDelete.value)
      store.dispatch('models/getSeedSegmentsForLookalike', {
        id: organizationId,
      })
      getData()
    }

    const openModal = () => createLookalikeModel.value.show()

    return {
      ...toRefs(state),
      organizationId,
      isLoading,
      lookalikeModels,
      canCreateLookalike,
      segmentsForLookalike,
      isLookalikeEngineSet,
      onModalClose,
      openModal,
      createSegment,
      getData,
      createLookalikeModel,
      whitelabel,
      triggerDelete,
    }
  },
}
</script>

<style lang="scss">
.blur {
  filter: blur(2px);
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.create-lookalike__modal {
  .modal-body {
    padding-top: 0;
  }
  .lookalike-modal__content {
    text-align: left;
  }

  .modal-content {
    overflow: visible !important;
    min-width: 546px !important;
  }

  .lookalike-modal__content {
    padding-top: 10px;
    color: #667c99;

    p {
      font-size: 12px;
      margin-bottom: 0;
    }

    h6 {
      font-size: 14px;
      color: #667c99;
      line-height: 18px;
    }

    .segment__select {
      .vs__selected-options {
        flex-wrap: nowrap;
        max-width: calc(100% - 40px);
      }

      .vs__selected {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
        color: #6e6b7b;
        margin-top: 5px;
      }

      .vs__dropdown-option {
        white-space: normal;
        word-break: break-all;

        &:hover {
          cursor: pointer;
        }

        &.vs__dropdown-option--highlight {
          background: rgba(29, 121, 242, 0.12);
          color: #1d79f2;
        }
      }

      .vs__dropdown-option--selected::after {
        right: 5px;
      }

      .disabled__prefix {
        font-size: 11px;
        color: gray;
        margin-left: 4px;
        display: inline-block;
        vertical-align: middle;
        font-style: italic;
      }
      .vs__selected-options {
        max-width: calc(100% - 55px);
        display: flex;
        flex-wrap: nowrap;
        padding: 0 2px;
        flex-basis: 100%;
        flex-grow: 1;
        position: relative;
      }
    }
  }
}
</style>
