<template>
  <div>
    <div :class="{ blur: !isLookalikeEngineSet && !isLoading }">
      <div class="lookalike-segments__header">
        <ExportButton
          id="export-lookalike-segments"
          :is-loading="isLoading"
          :get-file="getFile"
          :exporting="exporting"
          :has-data="lookalikeSegments.totalRecords > 0"
        />
        <FilterList
          ref="filterLookalikes"
          :filters="lookalikeFilters"
          :applied-filters="lookalikeSegments.filters"
          :loading="filtersLoading"
          :has-data="lookalikeSegments.totalRecords > 0"
          @update-listing="getData"
        />
        <SearchInputField
          id="search-lookalike-segments"
          :data="lookalikeSegments"
        />
      </div>
      <div
        v-if="lookalikeSegments.filters.length"
        class="lookalike-segments__header"
      >
        <ActiveFilters
          :applied-filters="lookalikeSegments.filters"
          :filters="lookalikeFilters"
          @remove-filter="removeFilter"
        />
      </div>
      <div class="table__wrapper">
        <CustomTable
          :data="lookalikeSegments"
          type-of-table="lookalike-segments"
          :is-pagination-enabled="true"
          :is-loading="isLoading"
          @update-table="getData"
          @clear-filters="clearFilters"
        >
          <template #row-data="{ data }">
            <LookalikeSegmentsRow :data="data" />
          </template>
        </CustomTable>
      </div>
      <DeleteModal
        :title="$t('lookalike.deleteLookalikeSegment')"
        :type="$t('lookalike.lookalikeSegment')"
        @delete="triggerDelete"
      />
    </div>
    <div class="info" v-if="!isLookalikeEngineSet && !isLoading">
      <img alt="Vue logo" src="@/assets/svgs/i-warning.svg" />
      <span>{{ $t('lookalike.notEnableMessage') }}</span>
    </div>
  </div>
</template>

<script>
import { lookalike } from '@/api'
import LookalikeSegmentsRow from '@/components/table-data/LookalikeSegmentsRow.vue'
import store from '@/store'
import {
  ActiveFilters,
  // utility components
  CustomTable,
  DeleteModal,
  ExportButton,
  FilterList,
  SearchInputField,
  //utility functions
  createDownloadLink,
  handleLastRowDelete,
} from '@nodus/utilities-front'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'

export default {
  name: 'LookalikeSegmentsTable',
  components: {
    CustomTable,
    SearchInputField,
    ExportButton,
    DeleteModal,
    LookalikeSegmentsRow,
    FilterList,
    ActiveFilters,
  },

  setup() {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params
    const isLoading = ref(true)
    const filtersLoading = ref(true)
    const { remove } = lookalike

    const lookalikeSegments = computed(
      () => store.getters['segments/getSegments']
    )
    const lookalikeFilters = computed(
      () => store.getters['segments/lookalikeFilters']
    )
    const IDtoDelete = computed(() => store.getters['segments/IdtoDelete'])
    const isLookalikeEngineSet = computed(() => store.getters.lookalikeEnabled)

    const getData = async (filters) => {
      if (filters) {
        localStorage.setItem(
          `lookalikeFilters-${organizationId}`,
          JSON.stringify(filters)
        )
        store.commit('segments/APPLY_LOOKALIKE_FILTERS', filters)
        store.commit('segments/SET_TABLE_PAGE', 1)
      }
      isLoading.value = true
      await store.dispatch('segments/getSegments', {
        id: organizationId,
      })
      isLoading.value = false
    }

    onMounted(async () => {
      await store.dispatch('getAiFeatures', { id: organizationId })
      if (!isLookalikeEngineSet.value) {
        store.commit('models/SET_SEGMENTS', [])
        isLoading.value = false
        return
      }
      await store
        .dispatch('segments/getLookalikeFilters', { organizationId })
        .then(() => (filtersLoading.value = false))
      getData()
    })

    const triggerDelete = async () => {
      handleLastRowDelete(
        lookalikeSegments.value,
        store,
        'segments/SET_TABLE_PAGE'
      )
      await remove(organizationId, IDtoDelete.value)
      getData()
    }

    const exporting = ref(false)

    const getFile = async (format) => {
      exporting.value = true
      const fileName = `Lookalike-Segments_${organizationId}.${
        format === 1 ? 'xlsx' : 'csv'
      }`
      const response = await store.dispatch('segments/getSegmentsFile', {
        id: organizationId,
        format,
      })
      createDownloadLink(response, fileName)
      exporting.value = false
    }

    const removeFilter = (filter) =>
      vm.$refs.filterLookalikes.clearFilter(filter)

    const clearFilters = () => {
      isLoading.value = true
      vm.$refs.filterLookalikes.clearFilters()
    }

    return {
      lookalikeSegments,
      isLookalikeEngineSet,
      triggerDelete,
      getData,
      isLoading,
      getFile,
      exporting,
      removeFilter,
      lookalikeFilters,
      filtersLoading,
      clearFilters,
    }
  },
}
</script>

<style lang="scss" scoped>
.blur {
  filter: blur(2px);
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.lookalike-segments__header {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-bottom: 20px;

  &:nth-child(2) {
    justify-content: flex-start;

    section {
      flex-direction: row;
    }
  }

  .table__search {
    padding: 0;
  }
}
</style>
