// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bisko-app__lookalike .blur {
  filter: blur(2px);
  pointer-events: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.create-lookalike__modal .modal-body {
  padding-top: 0;
}
.create-lookalike__modal .lookalike-modal__content {
  text-align: left;
}
.create-lookalike__modal .modal-content {
  overflow: visible !important;
  min-width: 546px !important;
}
.create-lookalike__modal .lookalike-modal__content {
  padding-top: 10px;
  color: #667c99;
}
.create-lookalike__modal .lookalike-modal__content p {
  font-size: 12px;
  margin-bottom: 0;
}
.create-lookalike__modal .lookalike-modal__content h6 {
  font-size: 14px;
  color: #667c99;
  line-height: 18px;
}
.create-lookalike__modal .lookalike-modal__content .segment__select .vs__selected-options {
  flex-wrap: nowrap;
  max-width: calc(100% - 40px);
}
.create-lookalike__modal .lookalike-modal__content .segment__select .vs__selected {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  color: #6e6b7b;
  margin-top: 5px;
}
.create-lookalike__modal .lookalike-modal__content .segment__select .vs__dropdown-option {
  white-space: normal;
  word-break: break-all;
}
.create-lookalike__modal .lookalike-modal__content .segment__select .vs__dropdown-option:hover {
  cursor: pointer;
}
.create-lookalike__modal .lookalike-modal__content .segment__select .vs__dropdown-option.vs__dropdown-option--highlight {
  background: rgba(29, 121, 242, 0.12);
  color: #1d79f2;
}
.create-lookalike__modal .lookalike-modal__content .segment__select .vs__dropdown-option--selected::after {
  right: 5px;
}
.create-lookalike__modal .lookalike-modal__content .segment__select .disabled__prefix {
  font-size: 11px;
  color: gray;
  margin-left: 4px;
  display: inline-block;
  vertical-align: middle;
  font-style: italic;
}
.create-lookalike__modal .lookalike-modal__content .segment__select .vs__selected-options {
  max-width: calc(100% - 55px);
  display: flex;
  flex-wrap: nowrap;
  padding: 0 2px;
  flex-basis: 100%;
  flex-grow: 1;
  position: relative;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
