// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bisko-app__lookalike .lookalike-screen__container {
  max-width: 1117px;
  min-width: 1117px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 120px);
}
.bisko-app__lookalike .lookalike-create-model .form-group {
  width: 50%;
}
.bisko-app__lookalike .lookalike-create-model .form-group label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #667c99;
}
.bisko-app__lookalike .lookalike-create-model .form-group input {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #052d61;
  margin-top: 6px;
  margin-bottom: 16px;
}
.bisko-app__lookalike .lookalike-create-model .form-group span {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #667c99;
}
.bisko-app__lookalike .lookalike-create-model .lookalike__header {
  font-size: 16px;
  color: #173163;
}
.bisko-app__lookalike .lookalike-create-model .lookalike__header--margin {
  margin-bottom: 24px;
}
.bisko-app__lookalike .lookalike-create-model .lookalike__name-information {
  font-size: 12px;
  margin-bottom: 40px;
}
.bisko-app__lookalike .scatter-chart {
  margin: 0 0 3rem 1rem;
  position: relative;
}
.bisko-app__lookalike .scatter-chart .echart-reach {
  transform: rotate(-90deg);
  position: absolute;
  left: -40px;
  top: 50%;
}
.bisko-app__lookalike .scatter-chart .echart-similarity {
  position: absolute;
  bottom: -20px;
  left: 50%;
}
.bisko-app__lookalike .scatter-chart .apexcharts-tooltip {
  background: transparent !important;
  border: none;
  box-shadow: none;
  padding: 4px;
}
.bisko-app__lookalike .scatter-chart .apexcharts-tooltip .custom-tooltip {
  background: rgba(5, 45, 97, 0.8) !important;
  box-shadow: 2px 2px 3px rgb(5, 45, 97);
  border-radius: 4px;
  margin: 0;
  padding: 8px;
  color: white;
  line-height: 18px;
}
.bisko-app__lookalike .scatter-chart .apexcharts-tooltip .custom-tooltip.disabled {
  background: rgba(227, 72, 80, 0.9) !important;
  box-shadow: 2px 2px 3px rgb(227, 72, 80);
}
.bisko-app__lookalike .scatter-chart .apexcharts-tooltip .custom-tooltip__info {
  margin-top: 10px;
  text-align: center;
}
.bisko-app__lookalike .scatter-chart .apexcharts-tooltip .custom-tooltip__info svg {
  width: 18px;
  height: 18px;
}
.bisko-app__lookalike .scatter-chart .apexcharts-tooltip .custom-tooltip__info span {
  font-weight: bold;
  font-size: 12px;
}
.bisko-app__lookalike .scatter-chart .apexcharts-tooltip .custom-tooltip__info.disabled {
  color: slategray !important;
  margin-top: 6px !important;
}
.bisko-app__lookalike .scatter-chart .apexcharts-tooltip .custom-tooltip__info.disabled svg {
  display: none;
}
.bisko-app__lookalike .scatter-chart .apexcharts-marker {
  fill-opacity: 0.3;
  stroke-opacity: 1;
  stroke-width: 1;
  stroke: #1d79f2;
}
.bisko-app__lookalike .scatter-chart .apexcharts-marker:hover {
  cursor: pointer;
  r: 13;
}
.bisko-app__lookalike .scatter-chart .apexcharts-marker[fill=disabled] {
  stroke: #e34850;
  fill: #e34850;
  fill-opacity: 0.7;
}
.bisko-app__lookalike .scatter-chart .apexcharts-marker[fill=disabled]:hover {
  stroke-width: 1;
  fill-opacity: 1 !important;
  cursor: not-allowed;
  r: 9;
}
.bisko-app__lookalike .scatter-chart .apexcharts-marker[selected=true] {
  fill-opacity: 1;
  stroke-width: 1;
  r: 12;
}
@media only screen and (max-width: 1440px) {
.bisko-app__lookalike .lookalike-screen__container {
    width: 100%;
    min-width: auto;
}
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
