<template>
  <div>
    <!-- Column: Similarity -->
    <span v-if="data.column.field === 'similarity'">
      {{ data.row.similarity }} %
    </span>

    <!-- Column: Name -->
    <span v-else-if="data.column.field === 'lookalikeSegmentName'">
      <ReadMore :text="data.row.lookalikeSegmentName" />
    </span>

    <!-- Column: Reach -->
    <span v-else-if="data.column.field === 'reach'">
      {{ data.row.reach.toLocaleString() }}
    </span>

    <!-- Column: Integration -->
    <span v-else-if="data.column.field === 'integrations'">
      <div class="table-list-integrations-container">
        <IntegrationColumn
          :row-id="data.row.id"
          :activations="data.row.activations"
        />
      </div>
    </span>

    <!-- Column: Actions -->
    <div
      v-else-if="data.column.field === 'actions'"
      class="table-list-actions-container"
    >
      <DropdownActions
        :id="'lookalike-segment-actions'"
        :items="dropdownActions"
      />
    </div>

    <!-- Column: Common -->
    <span v-else>
      {{ data.formattedRow[data.column.field] }}
    </span>
  </div>
</template>

<script>
import {
  DropdownActions,
  IntegrationColumn,
  ReadMore,
} from '@nodus/utilities-front'
import { getCurrentInstance } from 'vue'

export default {
  props: {
    data: {
      type: [Array, Object],
    },
  },
  name: 'LookalikeSegmentsData',
  components: {
    DropdownActions,
    IntegrationColumn,
    ReadMore,
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const { organizationId } = vm.$route.params

    const dropdownActions = [
      {
        id: 'edit-lookalike-segment',
        icon: 'Edit',
        text: 'Edit',
        disabled: !props.data.row.canEdit,
        to: {
          name: 'edit-lookalike-segment',
          params: {
            organizationId,
            modelId: props.data.row.id,
          },
        },
      },
      {
        id: 'delete-lookalike-segment',
        icon: 'Delete',
        text: 'Delete',
        type: 'danger',
        disabled: !props.data.row.canEdit,
        modal: 'modal-small-delete',
        action: () =>
          vm.$store.commit('segments/SET_ID_TO_DELETE', props.data.row.id),
      },
    ]

    return {
      organizationId,
      dropdownActions,
    }
  },
}
</script>

<style lang="scss">
.activations__tooltip {
  display: flex;
  flex-direction: column;
}
</style>
